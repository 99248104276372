/*
 * @Descripttion: 全局过滤器
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-27 11:15:18
 */
import vue from 'vue'
console.log("filter");
const processFilter = status => {
  if(status == '00')
    return '待发布'
  else if(status == '01')
    return '草稿'
  else if(status == '10')
    return '进行中'
  else if(status == '11')
    return '待报价'
  else if(status == '12')
    return '报价中'
  else if(status == '13')
    return '待确定'
  else if(status == '14')
    return '待通知'
  else if(status == '20')
    return '已结束'
  else if(status == '21')
    return '已结束'
  else if(status == '22')
    return '已终止'
  else if(status == '23')
    return '被修改'
}

/**
 * @Description: 项目分类
 * @Author: Niklaus
 * @Date: 2021-09-16 14:18:44
 */
const typeFilter = type => {
  if (type == 'A')
    return '货物'
  else if (type == 'B')
    return '工程'
  else if (type == 'C')
    return '服务'
}

const complementPoint = (num, pointNumber) => {
  let numberStr =  num + ""
  let array =  numberStr.split(".")
  return array.length > 1 ? numberStr + "0".repeat( pointNumber - array[1].length ) : numberStr+"." + "0".repeat(pointNumber)
}

const payFlagFilter = payFlag => {
  if(payFlag == '0')
    return '未收款'
  else if(payFlag == '1')
    return '已收款'
  else
    return '未收款'
}

const chargeFlagFilter = chargeFlag => {
  if(chargeFlag == '0'||chargeFlag == ''||chargeFlag == null)
    return '待定'
  else if(chargeFlag == '1')
    return '需要收费'
  else
    return '不需要收费'
}

const purchaseType = type => {
  console.log(type);
  if(type == '1') {
    return '金额报价'
  } else if(type == '2') {
    return '清单报价'
  } else if(type == '3') {
    return '金额报价定制版'
  } else if(type == '4') {
    return '反向竞拍'
  } else if(type == '5') {
    return '定额报价'
  } else if(type == '6') {
    return '金额定制人工费'
  } else
    return ''
}

vue.filter('process',processFilter)
vue.filter('typeFilter',typeFilter)
vue.filter('complementPoint',complementPoint)
vue.filter('payFlagFilter',payFlagFilter)
vue.filter('chargeFlagFilter',chargeFlagFilter)
vue.filter('purchaseType',purchaseType)
